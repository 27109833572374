<template>
  <app-table
    :items="items"
    :columns="columns"
    :editable="false"
    :addable="false"
    title="Расходы на 1 учащегося"
    :searchable="false"
    :hide-pagination="true"
    :pagination="{ rowsPerPage: 0 }"
    :totalRow="total"
    :clickable="false"
  ></app-table>
</template>

<script>
import { ref, toRefs, computed } from "vue";
import AppTableVue from "../UI/table/AppTable.vue";

export default {
  props: {
    operations: {
      type: Array,
      require: true,
    },
  },
  setup(props) {
    const { operations } = toRefs(props);

    const columns = ref([
      {
        name: "title",
        field: "title",
        label: "Комментарий",
        align: "left",
        sortable: false,
        type: "string",
        mobile: "title",
      },
      {
        name: "price",
        field: "price",
        label: "Сумма",
        align: "right",
        sortable: false,
        type: "money",
        mobile: "subTitle",
      },
    ]);

    const items = computed(() => operations.value);

    const totalRow = computed(() => ({
      title: "ИТОГО:",
      price: items.value.reduce((acc, operation) => acc + operation.price, 0),
    }));

    return { columns, items, total: totalRow };
  },
  components: {
    "app-table": AppTableVue,
  },
};
</script>

<style lang="scss" scoped></style>
