import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";

import directives from "@/directives/index";
import AppPageVue from "./views/AppSkeleton/AppPage.vue";
import AppDialogVue from "./components/UI/AppDialog.vue";

const app = createApp(App)
  .use(Quasar, quasarUserOptions)
  .use(store)
  .use(router);

directives.forEach((directive) => {
  app.directive(directive.name, directive);
});

app.component("app-page", AppPageVue);
app.component("app-dialog", AppDialogVue);

app.mount("#app");
